.PortfolioElement{
    position: relative;
    width: 300px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #6C6ADC;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.PortfolioElement >h3{
    left: 20px;
    bottom: 15px;
    margin: 0;
    color: white;
    position: absolute;
}

.PortfolioElement-Media-Wrapper{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 75%;
    opacity: 1;
    transition: 
    opacity 150ms;
}

.PortfolioElement-Media-Wrapper > img{
    background-color: white;
    position: absolute;
    width: 100%;
    height: 140px;
    object-fit: cover;
    opacity: 1;
    filter: 
    brightness(90%);
    border-radius: 20px 20px 0 0;
}

.PortfolioElement-Preview-Wrapper{
    color: #F5F5F5;
    height: 140px;
    opacity: 0;
    transition: 
    padding 150ms ease-out,
    opacity 150ms;
}

.PortfolioElement-Preview-Wrapper > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (hover: hover) and (pointer: fine){
    .PortfolioElement:hover .PortfolioElement-Preview-Wrapper{
        opacity: 1;
    }

    .PortfolioElement:hover .PortfolioElement-Media-Wrapper{
        opacity: 0;
    }
}

@media (prefers-reduced-motion: reduce){
    .PortfolioElement-Media-Wrapper{
        transition: 
        opacity 0ms !important;
    }
    .PortfolioElement-Text-Wrapper{
        transition: 
        padding 0ms,
        opacity 0ms !important;
    }
}