.PortfolioModal-Content-Wrapper{
    display: flex;
    align-items: center;
    padding: 25px 25px 35px 25px;
    gap: 40px;
    display: flex;
    flex-direction: column;
}

.PortfolioModal-Content-Section{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 46px;
}

.PortfolioModal-Media-Wrapper{
    background-color: lightgray;
    width: 460px;
    aspect-ratio: 16/9;
    border-radius: 20px;
    overflow: hidden;
}

.PortfolioModal-Media-Wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.PortfolioModal-Media-Wrapper video{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.PortfolioModal-Text-Wrapper{
    width: 450px;
    padding-bottom: 20px;
}

.PortfolioModal-Text-Wrapper > h3{
    margin: 0;
    padding-top: 0px;
}

.PortfolioModal-Text-Wrapper > p{
    margin: 0;
    padding-top: 16px;
    padding-right: 50px;
}

@media only screen and (max-width: 1100px) {
    .PortfolioModal-Content-Wrapper{
        gap: 50px;
    }
    
    .PortfolioModal-Content-Section{
        gap: 34px;
        flex-direction: column;
    }

    .PortfolioModal-Text-Wrapper{
        padding-left: 5px;
    }

    .PortfolioModal-Text-Wrapper > h3{
        padding-top: 0px;
    }
}

@media only screen and (max-width: 600px) {

    .PortfolioModal-Media-Wrapper{  
        width: 100%;
    }
    .PortfolioModal-Text-Wrapper{
        width: 100%;
    }
}