.Modal{
    background-color: #F5F5F5;
    color: #000000;
    height: fit-content;
    width: 1100px;
    margin: 150px 0;
    border-radius: 20px;
    padding: 0px;
    cursor: default;
    overflow: hidden;
    position: relative;
}

.Modal-EntryImage-Wrapper{
    position: absolute;
    background-color: #000000;
    width: 100%;
    aspect-ratio: 16/9;
    overflow: hidden;
}

.Modal-EntryImage-Wrapper > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Modal-Content-Wrapper{
    margin-top: min(30vw, 350px);
    padding: 0 20px;
    border-radius: 20px;
    background-color: #ffffff;
    position: relative;
    z-index: 10;
}

.Modal-Content-Header{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Modal-Content-Wrapper h1{
    margin: 0;
}

.Modal-Tech-Item-Container {
    padding: 8px 0px;
    display: flex;
    gap: 10px;
    justify-content: left;
}

.Modal-Tech-Item {
    font-size: 14px;
    padding: 3px 10px;
    border-radius: 16px;
    background-color: red;
    color: white;
    font-weight: bold;
}

.Modal-Tech-Item-Container > .EngineTag {
    background-color: black;
}

.Modal-Tech-Item-Container >  .LanguageTag {
    background-color: #6C6ADC;
}

.Modal-Tech-Item-Container >  .FocusTag {
    background-color: #AB5BB2;
}

.Modal-Content-Header-Spacer{
    flex: 10 0 30px;
}

.Modal-Content-Links-Wrapper {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1 1 150px;
}

.Modal-Content-Link {
    padding: 11px 16px;
    border-radius: 10px;
    color: white;
    text-decoration: inherit;
    text-align: center;
    
}

.Modal-Content-Link.Inactive {
    background-color: #D9D9D9;
    cursor: default;
    pointer-events: none;
}
.Modal-Content-Link.Active {
    background-color: #848484;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Modal-Content-Link h4 {
   margin: 0;
   font-size: 16px;
}


.Modal-Content-Section{
    margin: 50px 0px;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
}

.Modal-Content-Section-Media-Wrapper{
    background-color: black;
    color: white;
    width: 50%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    align-items: center;
    flex: 1.1 1 440px;
    overflow: hidden;
}

.Modal-Content-Section-Media-Wrapper > video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Modal-Content-Section-Media-Wrapper > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Modal-Content-Section-Text-Wrapper{
    padding-right: 20px;
    padding-left: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    flex: 1 1 400px;
}

.Modal-Content-Section-Text-Wrapper h3{
    margin: 0;
}

.Modal-Content-Section-Text-Wrapper p{
    margin: 0;
    padding: 1em 0;
    padding-right: 30px;
}

.Modal-Content-Implementations {
    padding-bottom: 40px;
}

.Modal-Content-Implementations > h3{
    margin: 0;
    padding: 1em 0px;
}

.Modal-Cross-Background{
    position: fixed;
    bottom: 40px;
    right: 40px;
    transform: translate(50%, 50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: blur(50px);
    background-color: black;
    opacity: 0;
    z-index: 10;
}  

.Modal-Cross-Wrapper{
    position: fixed;
    bottom: 40px;
    right: 40px;
    transform: translate(50%, 50%);
    background-color: #F5F5F5;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
    z-index: 10;
}  

.Modal-Cross-Wrapper svg {
    fill: #848484;
    position: fixed;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    scale: 0.375;
}  

@media only screen and (max-width: 1020px) {
    .Modal{
       border-radius: 0px;
    }
}

@media only screen and (max-width: 1200px) {
    .Modal{
        margin: 0px 0;
    }

    .Modal-Cross-Background{
        opacity: 0.9;
    }  
    .Modal-Cross-Wrapper{
        opacity: 1;
    }  
}
