header {
    display: flex;
    justify-content: space-between;
    padding: 3vw 5vw;
    padding-bottom: 0;
    height: 4em;
    align-items: center;
    flex: 0 0 auto;
    transition: padding 150ms ease;
    z-index: 110;
}

header a {
    color: inherit;
    text-decoration: inherit;
}

header a.active {
    font-weight: bold;
}

.Nav-Bar > a + a {
    margin-left: 1.5em;
}

header > div {
    display: inline-flex;
    align-items: center;
}

header .icon {
    width: 2em;
    height: 2em;
}

header .LinkedInLogo
{
    width: 100%;
    height: 100%; 
}

header .GitHubLogo
{
    width: 100%;
    height: 100%;   
}
header .MainLogo-Wrapper {
    height: 3em; /*or 3vw*/ 
    width: 4.5em;
}
header .MainLogo {
    height: 100%;
    width: 100%;
}
header .MainLogo--White {
    fill: white;
}

.Header-Burger-Box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 35px;
    height: 35px;
}

.Header-Burger-Box > .Bun-Top{
    height: 1px;
    width: 100%;
    background-color: black;
    transition: 
        all 300ms ease;
    transform-origin: top left;
}

.Bun-Top.WhiteStripes{
    transform: 
        translateY(-4px)
        translateX(4px)
        rotate(45deg);  
}

.Header-Burger-Box > .Patty{
    height: 16px;
    width: 100%;
}

.Header-Burger-Box > .Bun-Bottom{
    height: 1px;
    width: 100%;
    background-color: black;
    transition: 
        all 300ms ease;
    transform-origin: bottom left;
}

.Bun-Bottom.WhiteStripes{
    transform: 
        translateY(4px)
        translateX(4px)
        rotate(-45deg);  
}

.WhiteStripes{
    background-color: white !important;
}

.Header-Burger-Menu {
    background-color: black;
    color: whitesmoke;
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
}


.Header-Burger-Menu .Links{
    color: inherit;
    text-decoration: inherit;
    font-size: 28px;
}

.Header-Burger-Menu .Icon{
    width: 30px;
    height: 30px;
    fill: white;
}

.Header-Burger-Menu .Icon svg{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 600px) {
    .header--open {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}
