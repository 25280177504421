.About-Wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.About{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 5vw;
    padding: 15vh 4vw 20vh 4vw;
}

.About-Media-Wrapper{
    background-color: black;
    max-width: 370px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
    flex: 1 1 370px;
}

.About-Media-Wrapper > img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.About-Text-Wrapper{
    max-width: 455px;
}

.About-Text-Wrapper h1{
    margin: 0;
    padding-bottom: 6px;
    color: black;
}

.About-Text-Wrapper h3{
    margin: 0;
    color: black;
}

.About-Text-Wrapper p{
    padding-top: 38px;
    margin: 0;
    color: #1F1F1F;
}

@media only screen and (max-width: 1000px) {
    .About{
        padding-top: 10vw;
    }
}