.Portfolio-Wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Portfolio {
    padding-top: 1em;
    padding-bottom: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Portfolio-Section{ 
    padding: 40px 80px;
    width: 67vw;
}

.Portfolio-Section-Grid{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
    row-gap: 60px;
    column-gap: 60px;
    justify-content: center;
    padding: initial;
}

.Portfolio-Section-Grid > h2{
    margin: 0;
    grid-column: 1/-1;
    margin-bottom: -20px;
}

.Portfolio-Section-Grid-Box{
    background-color: black;
    width: 300px;
    height: 200px;
    flex-shrink: 0;
}

.Portfolio-Section-Grid-Box + .Portfolio-Section-Grid-Box{
    margin-right: 0px;
}

@media only screen and (max-width: 1520px) {
    .Portfolio-Section{ 
        padding: 40px 80px;
        width: 90vw;
    }
}