.GameCard {
    position: absolute;
    transform-origin: center;
    height: 370px;
    width: 270px;
    border-radius: 20px;
    z-index: 10;
    background-color: #F5F5F5;
    transition: 
        scale 200ms ease-out,
        z-index 300ms ease-out,
        all 300ms ease-out;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.GameCard-Media-Wrapper {
    padding: 10px 10px 0px 10px;
    height: 170px;
}

.GameCard-Media-Wrapper > img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.GameCard-Text-Wrapper {
    padding: 8px 10px;
}

.GameCard-Title > h3{
    padding: 0px 10px;
    padding-bottom: 7px;
    margin: 0px;
    color: black
}

.GameCard-Text > p{
    padding: 0px 10px;
    height: 80px;
    margin: 0px;
    color: #606060;
}

.GameCard-Tech-Item-Container {
    padding: 0px 10px;
    padding-bottom: 12px;
    display: flex;
    gap: 10px;
    justify-content: left;
}

.GameCard-Tech-Item {
    padding: 2.5px 8px;
    border-radius: 16px;
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 10px;
}

.GameCard-Tech-Item-Container > .EngineTag {
    background-color: #353535;
}

.GameCard-Tech-Item-Container > .LanguageTag {
    background-color: #6C6ADC;
}

.GameCard-Tech-Item-Container > .FocusTag {
    background-color: red;
}

@media (prefers-reduced-motion: reduce){
    .GameCard {
        transition: 
            scale 0ms,
            z-index 0ms,
            all 0ms !important;
    }
}