.PortfolioModal-Wrapper{
    margin: auto;
    display: flex;
    height: fit-content;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #6C6ADC;
    border-radius: 20px;
    cursor: default;
}

.PortfolioModal-Headline-Wrapper{
    width: 100%;
}

.PortfolioModal-Headline-Wrapper > h2{
    margin: 0;
    padding: 25px 25px 0 25px;
}

.PortfolioModal-Cross-Background{
    position: fixed;
    bottom: 40px;
    right: 40px;
    transform: translate(50%, 50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    filter: blur(50px);
    background-color: black;
    opacity: 0;
}  

.PortfolioModal-Cross-Wrapper{
    position: fixed;
    bottom: 40px;
    right: 40px;
    transform: translate(50%, 50%);
    background-color: #F5F5F5;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    opacity: 0;
}  

.PortfolioModal-Cross-Wrapper svg {
    fill: #848484;
    position: fixed;
    bottom: 0;
    opacity: 1;
    width: 100%;
    height: 100%;
    scale: 0.375;
}

@media only screen and (max-width: 600px) {
    .PortfolioModal-Cross-Background{
        opacity: 0.9;
    }  
    .PortfolioModal-Cross-Wrapper{
        opacity: 1;
    }  
}
