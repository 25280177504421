.Home-Wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
}

/* Global two states for this site */

/* Shared */

.State-Wrapper{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.State-Wrapper svg{
    fill: #848484;
    height: 100%;
    width: 100%;
}

/* State 1 */

.State-Wrapper-Overview{

}

/* State 2 */

.State-Wrapper-DetailView{

}

/* ------------------------------ */


/* Site is devided into my Name and Latest Games Card */

/* ------------------------------------- */
/* Lastest Games Headline | ------------ */
/* -Lastest Games Cards-- | -----My----- */
/* ------Doodles--------- | ----Name---- */
/* -----Back Arrow------- | ------------ */
/* ------------------------------------- */

/* ------------------ */
/* Lastest Games side */
/* ------------------ */

.Home-LatestGames {
    overflow: visible;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    position: relative;
    height: fit-content;
    transition: 
        all 500ms;
}

.State-Wrapper-Overview .Home-LatestGames {
    align-items: center;
    cursor: pointer;
}

.State-Wrapper-DetailView .Home-LatestGames {
    align-items: center;
    margin-left: 0;
    justify-content: center;
}

/* Headline */

.Home-LatestGamesHeading-Wrapper{
    padding-top: 2vh;
    height: 10vh;
    width: 100%;
    position: relative;
    transition: 
        translate 500ms,
        opacity 500ms;
}

.State-Wrapper-Overview .Home-LatestGamesHeading{
    margin: 0;
    text-align: center;
    position: absolute;
    top: 125%;
    left: 50%;
    transform: 
    translate(-50%, -50%);
    transition: all 500ms;
}

@media (hover: hover) and (pointer: fine){
    .State-Wrapper-Overview .Home-LatestGames:hover .Home-LatestGamesHeading{
        margin: 0;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: 
        translate(-50%, -50%);
        transition: all 500ms;
    }
}

.State-Wrapper-DetailView .Home-LatestGamesHeading{
    margin: 0;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: 
    translate(-50%, -50%);
    transition: all 500ms;
}

/* Cards */

.Home-LatestGamesCards {
    display: flex;
    align-items: center;
    transition: 
        all 500ms;
}

/* Cards - Overview */

.State-Wrapper-Overview .Home-LatestGamesCards {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 0px;
    justify-content: center;
}

@media (hover: hover) and (pointer: fine){
    .State-Wrapper-Overview .Home-LatestGames:hover .Home-LeftCard {
        transform: 
            translate(41%, -2%)
            rotate(-23.43deg) !important;
     }
    
    .State-Wrapper-Overview .Home-LatestGames:hover .Home-MiddleCard {
        transform: 
            translate(0%, -10%)
            rotate(-6.14deg) !important;
     }
    
    .State-Wrapper-Overview .Home-LatestGames:hover .Home-RightCard {
        transform: 
            translate(-44%, -1%)
            rotate(12.58deg) !important;
     }
}

.State-Wrapper-Overview .GameCard{
    position: relative;
    transform-origin: center;
    height: 370px;
    width: 270px;
}

/* Cards - DetailView */

.State-Wrapper-DetailView .Home-LatestGamesCards {
    display: flex;
    flex-direction: row;
    gap: 100px;
    justify-content: center;
    height: fit-content;
}

.State-Wrapper-DetailView .GameCard{
    position: relative;
    transform-origin: center;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine){
    .State-Wrapper-DetailView .GameCard:hover {
        scale: 1.1;
    }
}

/* Doodles */

.State-Wrapper-Overview .Doodles{
    display: block;
}

.State-Wrapper-DetailView .Doodles{
    display: none;
}

.State-Wrapper-Overview .Doodles svg {
    transition: opacity 150ms ease-in-out;
}

.State-Wrapper-DetailView .Doodles svg {
    transition: opacity 0ms;
    opacity: 0;
    height: 0;
    width: 0;
}

@media (hover: hover) and (pointer: fine){
    .State-Wrapper-Overview .Home-LatestGames:hover .Doodles svg{
        opacity: 0;
    }
}

.Doodles .Arrow {
    position: absolute;
    width: 120px;
    height: 100px;
}

.Doodles .Lines {
    position: absolute;
    width: 120px;
    height: 100px;
}

.Doodles .Click {
    position: absolute;
    width: 120px;
    height: 100px;
}

.State-Wrapper-Overview .Doodles .Arrow {
    top: 60%;
    left: 87%;
    transform: 
        scale(1.5,-1.2)
        rotate(197deg);
}

.State-Wrapper-Overview .Doodles .Lines {
    top: 22%;
    left: 3%;
    transform: 
        scale(0.55)
        rotate(20deg);
    transition: opacity 150ms ease-in-out;
}

.State-Wrapper-Overview .Doodles .Click {
    top: 87%;
    left: 20%;
    transform: 
        scale(1.2)
        rotate(14deg);
    transition: opacity 150ms ease-in-out;
}

/* Home Controls - Arrows and Back Button */ 

.Home-Controls-Wrapper{
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.BackCross-Wrapper{
    background-color: #F5F5F5;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: opacity 150ms;
}

.BackCross-Wrapper svg{
    scale: 0.32;
}

.State-Wrapper-Overview .BackCross-Wrapper{
    display: none;
}

.State-Wrapper-DetailView .BackCross-Wrapper{
    display: block;
    cursor: pointer;
}

.Home-Mobile-Left {
    background-color: #F5F5F5;
    display: none;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: opacity 150ms;
    cursor: pointer;
}

.Home-Mobile-Left > svg{
    scale: 0.35;
}

.Home-Mobile-Right {
    background-color: #F5F5F5;
    display: none;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transition: opacity 150ms;
    cursor: pointer;
}

.Home-Mobile-Right > svg{
    scale: 0.35;
    rotate: 180deg;
}


/* ------------------ */
/*     Name side      */
/* ------------------ */

.Text-Wrapper {
    width: 600px;
    display: flex;
    flex-shrink: 0.5;
    height: 500px;
    transition: 
        all 500ms,
        width 500ms, 
        opacity 200ms;
    user-select: none;
    position: relative;
    overflow: visible;
}

.State-Wrapper-DetailView .Text-Wrapper {
    opacity: 0;
    width: 0px;
}

.Text-Inner-Wrapper {
    position: absolute;
    width: 600px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.Name-Header {
    margin: 0px;
}

/* Mobile */

@media only screen and (max-width: 1150px) {
    
    .State-Wrapper {
        flex-direction: column;
        justify-content: flex-start;
        margin: auto 0;
        scale: 1;
    }

    .Home-LatestGames {
        scale: 0.8;
    }

    .State-Wrapper-DetailView .Home-LatestGames{
        margin-bottom: 15vh;
    }
    
    .Text-Wrapper{
        scale: 0.8;
    }

    .State-Wrapper-Overview{
        transition: height 500ms;
    }

    .State-Wrapper-Overview .Home-LatestGamesHeading-Wrapper{
        opacity: 0;
        height: 0;
    }

    .State-Wrapper-Overview .BackCross-Wrapper{
        height: 0; 
    }

    .Home-LatestGames {
        margin-left: 0vw;
    }

    .State-Wrapper-Overview .Text-Wrapper{
        width: 100%;
        height: 15vh;
    }
    
    .State-Wrapper-DetailView .Text-Wrapper {
        opacity: 0;
        height: 0vh;
        width: 100%;
    }
    
    .Text-Inner-Wrapper {
        position: absolute;
        width: 340px;
        left: 50%;
        top: 0%;
        transform: translateX(-50%);
    }

    .State-Wrapper-Overview .Doodles .Arrow {
        top: 50%;
        left: 87%;
    }
    
    .State-Wrapper-Overview .Doodles .Lines {
        top: 5%;
        left: 3%;
    }
    
    .State-Wrapper-Overview .Doodles .Click {
        top: 95%;
        left: 20%;
    }

}

@media only screen and (max-width: 900px) {
    .State-Wrapper-DetailView .Home-Mobile-Left{
        display: block;
    }
    .State-Wrapper-DetailView .Home-Mobile-Right{
        display: block;
    }
}

/* TODO: add reduced motion
@media (prefers-reduced-motion: reduce){

}
*/