.Carousel-Media-Wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0;
}

.Carousel-Media-Wrapper > video{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Carousel-Arrow-Left{
    position: absolute;
    background-color: #F5F5F5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 40px;
    cursor: pointer;
}

.Carousel-Arrow-Left > svg{
    position: absolute;
    fill: #848484;
    width: 100%;
    height: 100%;
    scale: 0.45;
}

.Carousel-Arrow-Right{
    position: absolute;
    background-color: #F5F5F5;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    top: 50%;
    right: 40px;
    cursor: pointer;
}

.Carousel-Arrow-Right > svg{
    position: absolute;
    fill: #848484;
    width: 100%;
    height: 100%;
    transform: rotate(180deg);
    scale: 0.45;
}

.Carousel-Selection-Area {
    display: flex;
    justify-content: center;
    gap: 21px;
}

.Carousel-Selection-Bubble {
    height: 8px;
    width: 8px;
    border: 2px solid #D9D9D9;;
    border-radius: 50%;
    cursor: pointer;
}

.Carousel-Selection-Bubble-Active {
    background-color: #D9D9D9;;
}

.Carousel-Text-Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.Carousel-Text-Wrapper-Wrapper {
    position: relative;
    overflow: hidden;
    height: 150px;
}

.Carousel-Text-Wrapper {
    width: 100%;
    position: absolute;
    left: 0;
}

.Carousel-Text-Wrapper > h3{
    margin: 0;
    padding-top: 22px;
}

.Carousel-Text-Wrapper > p{
    margin: 0;
    padding-top: 11px;
    width: 300px;
    text-align: center;
}

.slide-right-enter {
    position: absolute;
    left: -100%;
}

.slide-right-enter-active {
    transition: all 300ms ease-in;
    left: 0;
}

.slide-right-exit {
    position: absolute;
    left: 0;
}

.slide-right-exit-active {
    transition: all 300ms ease-in;
    left: 100%;
}

.slide-left {
    position: absolute;
    width: 100%;
    left: 0;
}

.slide-left-enter {
    position: absolute;
    top: 0;
    left: 100%;
}

.slide-left-enter-active {
    transition: all 300ms ease-in;
    left: 0;
}

.slide-left-exit {
    position: absolute;
    left: 0;
}

.slide-left-exit-active {
    transition: all 300ms ease-in;
    left: -100%;
}

@media only screen and (max-width: 1200px){
    .Carousel-Arrow-Left{
        width: 30px;
        height: 30px;
        left: 20px;
    }
    
    .Carousel-Arrow-Right{
        width: 30px;
        height: 30px;
        right: 20px;
    }
}